.outer {
    overflow-y: auto;
    max-height: 600px;
}

.outer {
    width: 100%;
    -layout: fixed;
}

.outer th {
    text-align: left;
    top: 0;
    position: sticky;
    background-color: white;
}

.lightgrey {
    background-color: rgb(138, 135, 135);
}