.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.numbers {
  margin-top: 20px;
  color: black;
  font-family: Tahoma;
  font-weight: 500;
  /* font-size: 350%; */
}

.centered-element {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dropdown {
  visibility: visible;
  position: absolute;
  top: 100%;
  right: 15%;
  height: 600px;
  width: 500px;
  z-index: 15;
  opacity: 1;
  background-color: white;
  background-size: cover;
  background-position: center;
  border: 1px solid rgba(0, 0, 0, 0.04);
  /* box-shadow: 0 16px 24px 2px rgba(165, 158, 158, 0.301) */
}

.dropdown.open {
  animation-name: mymove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.dropdown.close {
  visibility: hidden;
}

@keyframes mymove {
  0% {
    opacity: 0;
    visibility: visible;
    height: 0px;
    width: 0px;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    height: 600px;
    width: 500px;
  }
  /* 100% {
        visibility: hidden;
    } */
}

@keyframes mymoveBackward {
  0% {
    height: 600px;
    width: 500px;
  }
  99% {
    height: 1px;
    width: 1px;
  }
  100% {
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
  /* 100% {
        visibility: hidden;
    } */
}

.dropdown.hidden {
  visibility: hidden;
  /* position: absolute;
    top: 100%;
    right: 0;
    width: 300px;
    z-index: 13;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14); */
}

.squareimage2 {
  height: 105px;
  width: 105px;
  position: relative;
  margin: 5px;
  background-size: cover;
  background-position: center;
}

.squareimage3 {
  height: 100%;
  width: 100%;
  z-index: 17;
  position: relative;
  background-size: cover;
  background-position: center;
}

.scrollbasket {
  overflow-y: auto;
  max-height: 350px;
}

.scrollbasket2 {
  overflow-y: auto;
  max-height: 430px;
}

.del {
  width: 20px;
  height: 20px;
  float: right;
  position: relative;
  top: 3px;
  right: 3px;
  cursor: pointer;
}

.button1 {
  background-color: deepskyblue;
  /* Green */
  border: none;
  color: black;
  width: 100%;
  padding: 20px 45px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
}

.button2 {
  background-color: whitesmoke;
  /* Green */
  border: none;
  color: black;
  width: 100%;
  padding: 20px 45px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
}

.button3 {
  background-color: deepskyblue;
  /* Green */
  width: 320px;
  border: none;
  color: black;
  font-family: Tahoma;
  padding: 20px 45px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
}

.downbuttons {
  height: 250;
  background-color: white;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.style-5::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.style-5::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.style-5::-webkit-scrollbar-thumb {
  background-color: #0ae;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}

.text_basket {
  color: black;
  font-family: Tahoma;
  font-size: medium;
}

.text_basket_price {
  color: deepskyblue;
  font-family: Tahoma;
}

.alertStyle {
  background-color: #151515;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 3px;
  display: flex;
  z-index: 6;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
  font-family: "Arial";
  width: 480px;
  box-sizing: border-box;
}

.buttonStylec {
  margin-left: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
}

.menu {
  /* background-color: #333; */
  z-index: 18;
}

.nav {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: var(--black);
  overflow: hidden;
}

.dropdownnav {
}

.dropdownnav li {
  font-size: larger;
}

.dropdownnav.ago {
  font-size: x-large;
}
